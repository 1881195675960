import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { stringify } from "query-string";

import DataGrid from "./DataTable";
import { getJobData, queryWithoutEmptyString } from "../../lib/helperFunctions";

let BASE_API_URL = "https://find.jobget.com/api/jobs/search";

const DataTableContainer = ({ queries }) => {
  //this
  const [jobData, setJobData] = useState({ rows: [] });
  const [loading, setLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1);

  // const filteredArray = Object.entries(updatedQueries).filter(
  //   (item) => item[1] !== ""
  // );

  // const map = new Map(filteredArray);
  // const obj = Object.fromEntries(map);
  // console.log(obj);

  const loadData = (pageChange) => {
    const page = pageChange || currPage;
    console.log(page);
    const limit = queries.limit || 10;
    const from = (page - 1) * limit;

    let api = BASE_API_URL + "?sort=cpa&from=" + from;
    api +=
      "&" +
      stringify(
        queryWithoutEmptyString({
          ...queries,
          limit: limit,
        })
      );

    return getJobData(api, queries.channel).then((jobData) =>
      setJobData({
        rows: jobData.rows,
        count: Math.ceil(jobData.count / limit)
      })
    );
  };

  useEffect(() => {
    setJobData({ ...jobData, rows: [] });
    setLoading(true);
    loadData().then(() => setLoading(false));
  }, [queries]);

  const onPageChange = async (e, page) => {
    setCurrPage(page);
    setJobData({ ...jobData, rows: [] });
    setLoading(true);
    loadData(page).then(() => setLoading(false));
  };

  //pass the data to the grid component
  return (
    <Box sx={{ width: "100%", m: 2, height: "90vh" }}>
      <Typography variant="h6" align="center">
        QUERY RESULT
      </Typography>
      <DataGrid
        queries={queries}
        rows={jobData.rows}
        onPageChange={onPageChange}
        loading={loading}
        count={jobData.count}
      />
    </Box>
  );
};

export default DataTableContainer;
