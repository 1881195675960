import React from "react";
import { Box } from "@mui/material";
import QueryForm from "./QueryForm";
import { Typography } from "@mui/material";
const QueryFormContainer = ({ queries, setQueries, resetQueries }) => {
  return (
    <Box sx={{ width: "15%", m: 5 }}>
      <Typography variant="h6" align="center">
        QUERY PARAMETERS
      </Typography>
      <QueryForm
        setQueries={setQueries}
        queries={queries}
        resetQueries={resetQueries}
      />
    </Box>
  );
};

export default QueryFormContainer;
