import React, { useState } from "react";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from '@mui/icons-material/Done';

export const AddButton = ({ queries, params }) => {
  const [buttonState, setButtonState] = useState("add");

  const handleAdd = async () => {
    setButtonState("loading");

    const channel = queries.channel ?? "";
    const options = {
      method: "POST",
      body: JSON.stringify({ ...params.row, job_url: params.row.job_url + '?jg_channel=SFM'}),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        "https://europe-west3-x-alcove-347511.cloudfunctions.net/add-job",
        options
      );
      const responseJson = await response.json();
      console.log("Ovo je response", responseJson);
      setButtonState("success");
    } catch (e) {
      console.log(e);
      setButtonState("fail");
    }
  };

  if (buttonState == "loading") {
    return (
      <LoadingButton loading variant="outlined">
        Fetch data
      </LoadingButton>
    );
  }
  if (buttonState == "success") {
    return <DoneIcon color="green" />;
  }
  if (buttonState == "fail") {
    return <div>fail</div>;
  }

  return <Button variant="contained" onClick={handleAdd}>Add</Button>;
};
