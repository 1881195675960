const transformJobData = (data) => {
  return data.map((job) => {
    const newJob = {};
    // console.log(job);
    for (let [key, value] of Object.entries(job)) {
      // console.log(key);
      if (!value) newJob[key] = "-";
      else newJob[key] = value;
    }
    return newJob;
  });
};

export const getJobData = async (api, channel) => {
  const response = await fetch(api);
  const resData = await response.json();

  //imporove object destructured structure
  const jobDataObjects = resData.data.hits.map((item) => {
    return {
      id: item._id,
      ...item._source,
      update_time: item._source["_modifiedTS"],
      channelUrl: item._source.job_url + `?jg_channel=${channel}`
    };
  });
  return { rows: transformJobData(jobDataObjects), count: resData.data.count };
};

export function relativeDays(timestamp) {
  const rtf = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  const oneDayInMs = 1000 * 60 * 60 * 24;
  const daysDifference = Math.round(
    (timestamp - new Date().getTime()) / oneDayInMs
  );

  return rtf.format(daysDifference, "day");
}

const excludedQueries = ["random", "channel"];

export function queryWithoutEmptyString(queries) {
  return Object.keys(queries).reduce((prevObj, currValue) => {
    if (queries[currValue] !== "" && !excludedQueries.includes(currValue)) {
      return {
        ...prevObj,
        [currValue]: queries[currValue],
      };
    } else {
      return prevObj;
    }
  }, {});
}
