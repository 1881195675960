import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import CustomPagination from "../CustomPagination";
import { relativeDays } from "../../lib/helperFunctions";
import { renderCellExpand } from "../../utils/ShowDataOnHover";
import { AddButton } from "../AddButton";



export default function DataTable({ queries, rows, onPageChange, loading, count }) {
  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 300,
      renderCell: renderCellExpand,
    },
    {
      field: "source",
      headerName: "Source",
      width: 110,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "location",
      headerName: "Location",
      width: 170,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "company",
      headerName: "Company",
      width: 170,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "category",
      headerName: "Category",
      width: 110,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "posted_time",
      headerName: "Posted Date",
      width: 150,
      editable: true,
      valueFormatter: (params) => {
        return relativeDays(new Date(params.value).getTime());
      },
    },
    {
      field: "update_time",
      headerName: "Updated Date",
      width: 210,
      editable: true,
      valueFormatter: (params) => {
        const formattedDate = new Date(params.value).toLocaleString("en");
        return formattedDate;
      },
    },
    {
      field: "cpa",
      headerName: "CPA",
      type: "number",
      width: 70,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "cpc",
      headerName: "CPC",
      type: "number",
      width: 70,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "copy",
      headerName: "Copy",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          console.log(queries);
          navigator.clipboard.writeText(`${params.row.job_url}?jg_channel=SFM`)
          console.log(params)
          console.log(e);
        }
  
        return <Button onClick={onClick}>Copy</Button>;
      }
    },
    {
      field: "add",
      headerName: "Add",
      sortable: false,
      renderCell: (params) => {
        return <AddButton params={params} queries={queries} />
      }
    }
  ];

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          loading={loading}
          pagination
          pageSize={rows.length}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[rows.length]}
          disableSelectionOnClick
          sx={{ mt: 2 }}
          components={{
            Pagination: CustomPagination,
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            pagination: { onChange: onPageChange, count: count },
          }}
        />
      </div>
    </div>
  );
}
