import React from "react";
import { Box } from "@mui/material";
import { useState } from "react";
import "./App.css";
import DataTableContainer from "./components/Data/DataTableContainer";
import QueryFormContainer from "./components/Query/QueryFormContainer";

const QUERIES_INITIAL_STATE = {
  location: "",
  "search-term": "",
  radius: "",
  "excluded-companies": "",
  cpa: "",
  company: "",
  category: ""
};

function App() {
  const [queries, setQueries] = useState(QUERIES_INITIAL_STATE);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <QueryFormContainer
        setQueries={setQueries}
        queries={queries}
        resetQueries={() => setQueries(QUERIES_INITIAL_STATE)}
      />
      <DataTableContainer queries={queries} />;
    </Box>
  );
}

export default App;
