import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

//put it in .env file

export default function QueryForm({ queries, setQueries, resetQueries }) {
  const [formData, setFormData] = useState(queries);
  const [currentEditedField, setCurrentEditedField] = useState('');
  const [error, setError] = useState({});
  const handleChange = (e) => {
    setCurrentEditedField(e.target.id);
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    if(currentEditedField == 'channel') {
      handleSubmit();
    }
  }, [currentEditedField])

  const handleSubmit = () => {
    //Just so we can fetch data everytime we click run query - LOL
    setQueries({ ...formData, random: Math.random().toString(36).replace(/[^a-z]+/g, '')});
  };

  const handleChannelChange = (e) => {
    handleChange(e);
    handleSubmit();
  }

  return (
    <Box component="form" noValidate autoComplete="off">
      <div>
        <TextField
          fullWidth
          id="search-term"
          label="Search Term"
          placeholder=""
          variant="outlined"
          value={formData["search-term"]}
          size="small"
          margin="normal"
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="title"
          label="Title"
          placeholder="ex. Barista"
          variant="outlined"
          value={formData["title"]}
          size="small"
          margin="normal"
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="location"
          label="Location"
          placeholder="ex. New, NY"
          variant="outlined"
          size="small"
          margin="normal"
          value={formData["location"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="company"
          label="Company"
          variant="outlined"
          size="small"
          margin="normal"
          value={formData["company"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="category"
          label="Category"
          variant="outlined"
          size="small"
          margin="normal"
          value={formData["category"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="radius"
          label="Radius"
          placeholder="ex. 20.00"
          variant="outlined"
          type="number"
          size="small"
          margin="normal"
          value={formData["radius"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="excluded-companies"
          label="Excluded Companies"
          variant="outlined"
          size="small"
          margin="normal"
          value={formData["excluded-companies"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="cpa"
          label="CPA less than"
          placeholder="ex. 10.00"
          type="number"
          variant="outlined"
          size="small"
          margin="normal"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={formData["cpa_less_then"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="limit"
          label="Limit"
          placeholder="by default 10"
          variant="outlined"
          type="number"
          size="small"
          margin="normal"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={formData["limit"]}
          onChange={handleChange}
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="channel"
          label="Channel"
          variant="outlined"
          type="text"
          size="small"
          margin="normal"
          value={formData["channel"]}
          onChange={handleChannelChange}
        />
      </div>
      <div>
        <Button
          fullWidth
          variant="contained"
          size="small"
          sx={{ mb: 1, p: 1 }}
          style={{ fontSize: "12px" }}
          startIcon={<SendIcon style={{ fontSize: "12px" }} />}
          onClick={handleSubmit}
        >
          Run Query
        </Button>
      </div>
    {/* 
      It's just reseting query from state, not the actual text boxes, so it's halfay working and users will be confused.
      TODO: Make this to work correctly

      <div>
        <Button
          fullWidth
          variant="contained"
          size="small"
          sx={{ mb: 1, p: 1 }}
          style={{ fontSize: "12px" }}
          onClick={resetQueries}
        >
          Reset
        </Button>
      </div>
    
    */}
    </Box>
  );
}
